import { ToastProps } from "./toast";

type ToastCallback = (data: ToastProps) => void;

export class ToastHandler {
  handlers: ToastCallback[];
  toast?: ToastProps;
  constructor() {
    this.handlers = [];
  }
  create(data: ToastProps) {
    this.toast = data;
    this.callback();
    this.toast = undefined;
  }
  success(message: string) {
    this.create({ message, type: "success" });
  }
  error(message: string, timeout: number = 3000) {
    this.create({ message, type: "error", timeout });
  }
  createUnknkowError() {
    this.create({
      message: "Erreur, veuillez ré-éssayer ou revenir plus tard",
      type: "error",
    });
  }
  callback() {
    this.handlers.slice(0).forEach((handler) => (this.toast ? handler(this.toast) : ""));
  }
  on(handler: ToastCallback) {
    this.handlers.push(handler);
  }
}
