import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { RouterLink } from "../../atoms/routerLink/routerLink";
import { Text } from "src/components/atoms/text/text";
import { Icon } from "../../atoms/icon/icon";
import { bemModifier } from "src/helpers/bemModifier";
export type PageHeaderProps = {
  title: string;
  back?: any;
  add?: any;
  flat?: boolean;
};
export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  title,
  back,
  add,
  children,
  ...modifiers
}) => {
  const elementRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [elHeight, setElHeight] = useState<number>(0);
  useEffect(() => {
    if (!elementRef.current) return;
    const { height } = elementRef.current?.getBoundingClientRect();
    setElHeight(height);
  }, [elementRef]);
  return (
    <>
      <div ref={elementRef} className={bemModifier(modifiers, "page-header")}>
        <header className="page-header__wrap">
          <div className="page-header__back">
            {back && (
              <RouterLink className="page-header__back" to={back} transition="back">
                <Icon name="arrow_left" /> Retour
              </RouterLink>
            )}
            {add && (
              <RouterLink className="addItem" to={add}>
                <Icon name="plus" />
              </RouterLink>
            )}
          </div>
          <Text type="h1">{title}</Text>
          {children}
        </header>
      </div>
      <div className="page-header__sizer" style={{ height: elHeight + "px" }}></div>
    </>
  );
};
PageHeader.defaultProps = {};
