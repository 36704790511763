import React, { FunctionComponent, useContext } from "react";
import { Container } from "src/components/atoms/container/container";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { AppContext } from "src/contexts/appContext";
import { HealthAccidentButtons } from "./healthAccidentButtons";
export type HealthAccidentActionDoctorProps = {
  title: string;
  back: string;
  yesRoute: string;
  noRoute: string;
};
export const HealthAccidentActionDoctor: FunctionComponent<HealthAccidentActionDoctorProps> = ({
  title,
  back,
  yesRoute,
  noRoute,
  children,
}) => {
  const { strings } = useContext(AppContext);
  return (
    <>
      <PageHeader title={title} back={back} />
      {children && <Container>{children}</Container>}
      <HealthAccidentButtons
        yesRoute={yesRoute}
        noRoute={noRoute}
        title={strings!.pages.healthAccident.doctorConsulted}
      />
    </>
  );
};
