import React, { FunctionComponent } from "react";
import { MySkeleton } from "./mySkeleton";
// import { MySkeleton, SkeletonType } from "./listItemSkeleton";
export type ListRecipeSkeletonProps = {
  numberItem: number;
  // type?: SkeletonType;
};
export const ListRecipeSkeleton: FunctionComponent<ListRecipeSkeletonProps> = ({ numberItem }) => {
  return (
    <div className="listRecipes">
      <ul>
        {[...Array(numberItem)].map((_, index) => {
          return (
            <li className="recipe" key={index}>
              <div className="vertical">
                <p>
                  <MySkeleton type="text" width={80} height={11} />
                </p>
              </div>
              <div className="img">
                <MySkeleton type="rect" width={86} height={86} />
              </div>
              <div className="content">
                <MySkeleton type="text" width={170} height={18} />
                <MySkeleton type="text" width={100} height={18} />
                <p className="text--p4">
                  <MySkeleton type="text" width={180} height={11} />
                  <MySkeleton type="text" width={70} height={11} />
                </p>

                <div>
                  <MySkeleton type="text" width={58} height={18} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
