import React, { FC } from "react"; // importing FunctionComponent
import { ListItemFileSkeleton } from "src/components/atoms/skeleton/listItemFileSkeleton";
import { File } from "src/types/ApiItems";
import { ListDocumentItem } from "../listDocumentItem/listDocumentItem";

type ListDocumentProps = {
  files: Array<File>;
  loading?: boolean;
};

export const ListDocument: FC<ListDocumentProps> = ({ files, loading }) => {
  return (
    <ul>
      {loading && [1,2,3].map((id)=><ListItemFileSkeleton key={'docskeleton'+id} />)}
      {!loading && files?.map((item) => (
        <ListDocumentItem key={item.id} {...item} />
      ))}
    </ul>
  );
};
