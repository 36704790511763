import React, { FunctionComponent } from "react";
import { Page } from "src/layouts/page";
import { Container } from "../container/container";
import { Hero } from "../../molecules/hero/hero";
import { Icon } from "../icon/icon";
import { DescriptionSkeleton } from "./descriptionSkeleton";
import { MySkeleton } from "./mySkeleton";

export type DetailsSkeletonProps = {
  withImage?: boolean;
  withDescription?: boolean;
  title: string;
};

export const DetailsSkeleton: FunctionComponent<DetailsSkeletonProps> = ({
  withImage,
  withDescription,
  title,
}) => {
  return (
    <Page pageClass="layout__detail singleJob skeleton--detail">
      <>
        <Hero
          href="/"
          title={title}
          {...(withImage ? { imgBack: "/" } : {})}
          badge={undefined}
        ></Hero>
        <Container>
          <div className="text--subtitle">
            <MySkeleton type="text" width={90} height={14} />
          </div>
          <div className="text--h1">
            <MySkeleton type="text" width={280} height={29} />
            <MySkeleton type="text" width={210} height={29} />
          </div>

          <div className="publish">
            <Icon name="time" />
            <MySkeleton type="text" width={165} height={17} />
          </div>

          {withDescription && (
            <>
              <div className="separator" />
              <DescriptionSkeleton />
              <div className="separator" />
            </>
          )}
          <div style={{ marginTop: "32px" }}>
            <MySkeleton type="text" width={315} height={15} />
          </div>
          <MySkeleton type="text" width={290} height={17} />
          <MySkeleton type="text" width={310} height={17} />
          <MySkeleton type="text" width={300} height={17} />
        </Container>
      </>
    </Page>
  );
};
