import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { Badge } from "src/components/atoms/badge/badge";
import { Icon } from "src/components/atoms/icon/icon";
import { RouterLink } from "src/components/atoms/routerLink/routerLink";
import { bemModifier } from "src/helpers/bemModifier";

export type BreadcrumbItem = {
  link?: string | null;
  label: string;
};

export type BreadcrumbProps = {
  items: BreadcrumbItem[];
};

export const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({ items, ...modifiers }) => {
  return (
    <span className={bemModifier(modifiers, "breadcrumb")}>
      <div className="breadcrumb__grid">
        {items.map((item, i) => {
          return (
            <div key={i} className="breadcrumb__grid-item">
              <Badge>
                {item.link ? (
                  <RouterLink to={item.link} transition="back">
                    {item.label}
                  </RouterLink>
                ) : (
                  item.label
                )}
              </Badge>
              <Icon className="breadcrumb__chevron" name="chevronRight" />
            </div>
          );
        })}
      </div>
    </span>
  );
};

Breadcrumb.defaultProps = {
  items: [],
};
