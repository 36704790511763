import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";

type BadgeNotificationProps = {
  empty?: boolean;
  outline?: boolean;
  large?: boolean;
};

export const BadgeNotification: FunctionComponent<BadgeNotificationProps> = ({
  children,
  ...modifiers
}) => {
  return <span className={bemModifier(modifiers, "badgeNotification")}>{children}</span>;
};
