import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { BadgeNotification } from "../../atoms/badgeNotification/badgeNotification";
import { Text } from "../../atoms/text/text";

type DateCalendarProps = {
  notif?: boolean;
  disabled?: boolean;
  actual?: boolean;
  // day: "LU" | "MA" | "ME" | "JE" | "VE";
  day?: string;
  id?: number;
  setActual?: any;
  date: string;
};

export const DateCalendar: FunctionComponent<DateCalendarProps> = ({
  children,
  notif,
  id,
  setActual,
  day,
  date,
  ...modifiers
}) => {
  return (
    <li onClick={() => setActual(id)} className={bemModifier(modifiers, "dateCalendar")}>
      {notif && <BadgeNotification>!</BadgeNotification>}
      <Text type="p4">{day}</Text>
      <Text type="h3">{date}</Text>
    </li>
  );
};
DateCalendar.defaultProps = {
  setActual: () => {},
};
