import { EventItem } from "src/types/ApiItems";
import { FetchApi } from "./FetchApi";

export class EventsApi extends FetchApi {
  getAll() {
    return this.get<EventItem[]>("/events");
  }
  getById(id: number) {
    return this.get<EventItem>(`/events/${id}`);
  }
}
