import React, { useContext } from "react";
import { Page } from "src/layouts/page";
import { Text } from "src/components/atoms/text/text";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { ContactBox } from "src/components/molecules/contactBox/contactBox";
import { AppContext } from "src/contexts/appContext";

export const HealthAbsence = () => {
  const { strings } = useContext(AppContext);
  return (
    <Page pageClass="healthAbsence">
      <PageHeader title={strings!.pages.healthAbsence.pageTitle} back={routes.health} />

      <Container>
        <Text type="intro">{strings?.pages.healthAbsence.intro}</Text>
        <ContactBox
          description={strings?.contact.central.title}
          href={`tel:${strings?.contact.central.phoneNumber}`}
          badge={1}
          btnText={strings?.contact.central.phoneLabel}
          btnIcon="phone"
        />
        <ContactBox
          description={strings?.pages.healthAbsence.certificatTitle}
          link={routes.serviceRh}
          badge={2}
          btnText={strings?.pages.healthAbsence.certificatBtnLabel}
          btnIcon="mail"
        />
      </Container>
    </Page>
  );
};
