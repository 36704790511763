import { Announce, AnnounceDetail } from "src/types/ApiItems";
import { FetchApi } from "./FetchApi";
export type AnnounceCreate = {
  title: string;
  category: number;
  description: string;
  price: number;
  displayContact: boolean;
  images: any;
  vendorEmail: string;
  vendorPhoneNumber: string;
};
export type AnnounceUpdate = AnnounceCreate & {
  id: number;
};

export class SmallAnnouncesApi extends FetchApi {
  getAll() {
    return this.get<Announce[]>(`/smallAnnounces`);
  }
  getAllMy() {
    return this.get<Announce[]>(`/smallAnnounces/GetAllMy`, {
      cacheDuration: 10,
    });
  }
  getAnnounce(id: number) {
    return this.get<AnnounceDetail>(`/smallAnnounces/${id}`);
  }
  async create(announce: AnnounceCreate): Promise<false | number> {
    try {
      const id = await this.postFormData<number>(
        `/smallAnnounces`,
        this.objectToFormdata(announce, ["images"])
      );
      return id;
    } catch (e) {
      return false;
    }
  }
  update(announce: AnnounceUpdate) {
    this.deleteAnnounceFromStorage(announce.id);
    return this.putFormData<any>(`/smallAnnounces`, this.objectToFormdata(announce, ["images"]));
  }
  remove(id: number) {
    this.deleteAnnounceFromStorage(id);
    return this.delete<any>(`/smallAnnounces/${id}`);
  }
  private deleteAnnounceFromStorage(id) {
    this.removeFromStorage(`/smallAnnounces/${id}`);
  }
}
