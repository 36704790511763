import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button } from "src/components/atoms/button/button";
import localforage from "localforage";
import { LoadingScreen } from "../loadingScreen/loadingScreen";
import { localForageKeys } from "src/helpers/localForageKey";
import { Container } from "src/components/atoms/container/container";
import { version } from "../../../../package.json";
import { ErrorCodes } from "src/helpers/enum";
import { forceRefresh } from "src/helpers/miscHelpers";

export type ErrorScreenProps = {
  code?: number;
  info?: string | null;
  homeredirect?: boolean
};

export const ErrorScreen: FunctionComponent<ErrorScreenProps> = ({
  children,
  info,
  homeredirect = false,
  code = ErrorCodes.UNKNOWN_ERROR_SCREEN,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const handleRefresh = useCallback(async () => {
    try {
      if (homeredirect) {
        await localforage.clear();
        window.location.replace("/");
      }
      forceRefresh()
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [homeredirect])
  useEffect(() => {
    const checkBackHome = async () => {
      try {
        const backhome = await localforage.getItem(localForageKeys.backHome);
        if (backhome) {
          await localforage.removeItem(localForageKeys.backHome);
          window.location.replace("/");
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    checkBackHome();
  }, []);
  if (loading) return <LoadingScreen show></LoadingScreen>;
  return (
    <div className="error-screen loading-screen loading-screen--error">
      <Container>
        <div className="loading-sreen__content">
          <div className="loading-screen__message">
            {children}
            <div className="error-screen__btn">
              <Button onClick={() => handleRefresh()}>Recharger</Button>
            </div>
          </div>
        </div>
        <div className="error-screen__info">
          Code d'erreur: {code ?? 'UNKNOWN'}
          <br />
          version: {version}
          {!!info && (
            <div>
              <br />
              {info}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
