import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { Alert } from "../../../molecules/alert/alert";
import { Icon } from "../../../atoms/icon/icon";
import { Text } from "../../../atoms/text/text";
import { Recipe } from "../../recipe/recipe";
import { RestaurantMenu } from "src/types/ApiItems";

type ListRecipeProps = {
  restaurant?: RestaurantMenu;
};
export const ListRecipe: FunctionComponent<ListRecipeProps> = ({ restaurant }) => {
  if (!restaurant) return <></>;
  function decodeHtml(html) {
    if (html) {
      let txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
    return null;
  }
  const allMenus = restaurant.menus.map((recipe, index) => {
    const price = `CHF ${recipe.price.toFixed(2)}`;
    return (
      <Recipe
        type={recipe.category}
        imgUrl={recipe.imageUrl}
        title={recipe.title}
        description={recipe.description}
        price={price}
        key={index}
      ></Recipe>
    );
  });
  if (!restaurant.closing && restaurant.menus.length) {
    return (
      <div className="listRecipes">
        {restaurant.special && (
          <Alert key={restaurant.special.id}>{decodeHtml(restaurant.special.title)}</Alert>
        )}
        <ul>{allMenus}</ul>
      </div>
    );
  } else {
    return (
      <>
        <div className="restoClose">
          <Icon name="restoClose" />
          <Text type="h4" center>
            Restaurant fermé
          </Text>
          <Text type="p3" color="paleBrown" center>
            {restaurant.closing && <>{restaurant.closing.title}</>}
            {!restaurant.closing && (
              <> Le restaurant {restaurant.name} est exceptionnellement fermé aujourd'hui</>
            )}
          </Text>
        </div>
      </>
    );
  }
};
