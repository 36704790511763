import moment from "src/helpers/moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Page } from "src/layouts/page";
import { NewsDetail } from "src/types/ApiItems";
import { AppNewsType } from "src/types/dataTypes";
import { Badge } from "../../atoms/badge/badge";
import { Container } from "../../atoms/container/container";
import { Hero } from "../../molecules/hero/hero";
import { Icon } from "../../atoms/icon/icon";
import { NewsContent } from "../../molecules/newsBody/newsBody";
import { Text } from "../../atoms/text/text";
export type NewsDetailPageProps = {
  news: NewsDetail;
  type: AppNewsType;
  title: string;
  back: string;
};
export const NewsDetailPage: FunctionComponent<NewsDetailPageProps> = ({
  news,
  title,
  back,
  type,
}) => {
  const [publishDate, setPublishDate] = useState<String>();

  useEffect(() => {
    setPublishDate(moment(news.publishDate).format("DD MMMM YYYY"));
  }, [news]);
  return (
    <Page pageClass="layout__detail newsDetailPage">
      <Hero
        href={back}
        title={title}
        backColor={news.category === "Décès" ? "black" : undefined}
        image={news.imageUrl}
        badge={news.isImportant ? "important" : news.isNew ? "new" : undefined}
        small={news.category === "Décès"}
      ></Hero>
      <Container>
        <div className="newsDetailPage__meta">
          <Text type="subtitle">{news.category}</Text>
          <Badge>{news.division}</Badge>
        </div>

        <Text type="h1">{news.title}</Text>

        <div className="publish">
          <Icon name="time" />
          <Text type="p3" color="paleBrown">{`Publié le ${publishDate}`}</Text>
        </div>

        {news.newsBody1 && <NewsContent actu={news.newsBody1} type={type} />}
        {news.newsBody2 && <NewsContent actu={news.newsBody2} type={type} />}
        {news.newsBody3 && <NewsContent actu={news.newsBody3} type={type} />}
        {news.newsBody4 && <NewsContent actu={news.newsBody4} type={type} />}
        {news.newsBody5 && <NewsContent actu={news.newsBody5} type={type} />}
        {news.newsBody6 && <NewsContent actu={news.newsBody6} type={type} />}
        {news.newsBody7 && <NewsContent actu={news.newsBody7} type={type} />}
        {news.newsBody8 && <NewsContent actu={news.newsBody8} type={type} />}
        {news.newsBody9 && <NewsContent actu={news.newsBody9} type={type} />}
        {news.newsBody10 && <NewsContent actu={news.newsBody10} type={type} />}
      </Container>
    </Page>
  );
};
