import React, { FunctionComponent, useContext, useEffect, useState } from "react"; // importing FunctionComponent
import { AppContext } from "src/contexts/appContext";
import { bemModifier } from "src/helpers/bemModifier";

type ImageProps = {
  /** Color of button */
  id?: string;
  url?: string;
  width?: number;
  height?: number;
  cover?: boolean;
  contain?: boolean;
  hidePlaceholder?: boolean;
  alt?: string;
  placeholder?: string;
};

export const Image: FunctionComponent<ImageProps> = ({
  children,
  id,
  url,
  width,
  height,
  alt,
  hidePlaceholder,
  placeholder,
  ...modifiers
}) => {
  const { api } = useContext(AppContext);
  const [src, setSrc] = useState<string>("");
  const [isPlaceholder, setIsPlaceholder] = useState<boolean>(false);
  const storageKey = `${id}-${width}-${height}`;
  const setPlaceholder = () => {
    setSrc(placeholder ? placeholder : "/placeholder.jpg");
    setIsPlaceholder(true);
  };
  const saveInStorage = (data) => {
    try {
      sessionStorage.setItem(storageKey, data);
    } catch (e) {
      console.warn("Image not save in storage", e);
    }
  };
  const getFromStorage = () => {
    return sessionStorage.getItem(storageKey);
  };
  useEffect(() => {
    if (id) {
      const fromStorage = getFromStorage();
      if (fromStorage) {
        setSrc(fromStorage);
        setIsPlaceholder(false);
      } else {
        api.image
          .getById(id, { width, height })
          .then((dataurl) => {
            setSrc(dataurl);
            setIsPlaceholder(false);
            saveInStorage(dataurl);
          })
          .catch(() => {
            setPlaceholder();
          });
      }
    } else {
      setPlaceholder();
    }
  }, []); // eslint-disable-line
  if (!src) return <></>;
  if (hidePlaceholder && isPlaceholder) return <></>;
  return (
    <div className={bemModifier({ ...modifiers, placeholder: isPlaceholder }, "image")}>
      <img className="image__tag" src={src} alt={alt} />
    </div>
  );
};
Image.defaultProps = {
  alt: "",
};
