import React, { useContext, useEffect, useState } from "react";
import { Page } from "../../layouts/page";
import { ListHome } from "src/components/organisms/lists/listHome/listHome";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { version } from "../../../package.json";
import { AppContext, ListHomeItem } from "src/contexts/appContext";
import { getListingRoutes } from "../../helpers/routeHelpers";
import { EventItem, NewsEntreprise, OfferRH } from "src/types/ApiItems";
import moment from "src/helpers/moment";
import { useMsal } from "@azure/msal-react";

export const Home = () => {
  const { homeItems, api, readItems } = useContext(AppContext);
  const [homeNewItems, setHomeNewItems] = useState<ListHomeItem[]>(homeItems);
  const testApi = getListingRoutes(api);
  const [clickCount, setClickCount] = useState(0);
  const { instance } = useMsal();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    const tempHomeItems: Array<ListHomeItem> = [...homeNewItems];
    tempHomeItems.forEach(async (link: ListHomeItem) => {
      try {
        const found = testApi.find((route) => {
          return route.id === link.id;
        });
        if (found) {
          let notifs: NewsEntreprise[] | OfferRH[] = await found.getIsNew();
          if (readItems[found.type].length) {
            readItems[found.type].forEach((item) => {
              notifs = notifs.filter((notif) => notif.id !== Number(item.id));
            });
          }
          link.notif = notifs.length > 0 ? notifs.length : undefined;
        } else if (link.id === "events") {
          const notifs: EventItem[] = await api.events.getAll();
          let tabFilter = notifs.filter((content) => {
            // notif if the event is new and the event isn't over
            return content.isNew && moment(content.endDate) >= moment();
          });
          if (readItems["event"].length) {
            readItems["event"].forEach((item) => {
              tabFilter = tabFilter.filter((notif) => notif.id !== Number(item.id));
            });
          }
          link.notif = tabFilter.length > 0 ? tabFilter.length : undefined;
        }
      } catch (e) {
        console.error(e, "error");
      }
    });
    setTimeout(() => {
      setHomeNewItems(tempHomeItems);
    }, 1000);

    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    setClickCount(prevCount => prevCount + 1);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setClickCount(0);
    }, 1000);

    setTimeoutId(newTimeoutId);
  }

  useEffect(() => {
    if (clickCount === 5) {
      setClickCount(0);
      instance.logoutRedirect();
    }
  }, [clickCount, instance]);
  return (
    <Page pageClass="home">
      <div className="home__content">
        <PageHeader title="Accueil" />
        <Container small>
          <ListHome items={homeNewItems} />
        </Container>
      </div>
      <div className="home__version" >
        <span onClick={handleClick}>version: {version}</span>
      </div>
    </Page>
  );
};
