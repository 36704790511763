import React, { FunctionComponent, useContext } from "react";
import { Container } from "src/components/atoms/container/container";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { AppContext } from "src/contexts/appContext";
        import { Text } from "src/components/atoms/text/text";

export type HealthAccidentNothingTodoProps = {
  back: string;
};
export const HealthAccidentNothingTodo: FunctionComponent<HealthAccidentNothingTodoProps> = ({
  back,
}) => {
  const { strings } = useContext(AppContext);
  return (
    <>
      <PageHeader title={strings!.pages.healthAccident.notAbsentTitle} back={back} />
      <Container>
        <Text type="intro">{strings?.pages.healthAccident.nothingToDoIntro}</Text>
      </Container>
    </>
  );
};
