export const bemModifier = (modifiers: object, base: string) => {
  let classNames: String[] = [base];
  Object.keys(modifiers).forEach((key) => {
    if (typeof modifiers[key] === "string" && modifiers[key]) {
      classNames.push(base + "--" + modifiers[key]);
    } else if (modifiers[key] === true) {
      classNames.push(base + "--" + key);
    }
  });

  return classNames.join(" ");
};
