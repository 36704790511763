import React, { FunctionComponent, useContext } from "react";
import { routes } from "src/AppRouter";
import { Container } from "src/components/atoms/container/container";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Text } from "src/components/atoms/text/text";
import { AppContext } from "src/contexts/appContext";
import { HealthAccidentButtons } from "./healthAccidentButtons";
export type HealthAccidentIndexProps = {
  yesRoute: string;
  noRoute: string;
};
export const HealthAccidentIndex: FunctionComponent<HealthAccidentIndexProps> = ({
  yesRoute,
  noRoute,
}) => {
  const { strings } = useContext(AppContext);
  return (
    <>
      <PageHeader title={strings!.pages.healthAccident.declareTitle} back={routes.health} />
      <Container>
        <Text type="intro">{strings?.pages.healthAccident.declareIntro}</Text>
        <Text type="p3">{strings?.pages.healthAccident.declareGeTitle}</Text>
        <ul>
          <li>
            <p>{strings?.pages.healthAccident.declareSuvaTitle}</p>
            <span>{strings?.pages.healthAccident.declareGeContracts.suva}</span>
          </li>
          <li>
            <p>{strings?.pages.healthAccident.declareSwicaTitle}</p>{" "}
            <span>{strings?.pages.healthAccident.declareGeContracts.swica}</span>
          </li>
        </ul>
        <Text type="p3">{strings?.pages.healthAccident.declareValleeTitle}</Text>
        <ul>
          <li>
            <p>{strings?.pages.healthAccident.declareSuvaTitle}</p>
            <span>{strings?.pages.healthAccident.declareValleeContacts.suva}</span>
          </li>
          <li>
            <p>{strings?.pages.healthAccident.declareSwicaTitle}</p>{" "}
            <span>{strings?.pages.healthAccident.declareValleeContacts.swica}</span>
          </li>
        </ul>
        <div className="box">
          <Text type="p4">{strings?.pages.healthAccident.declareNote}</Text>
        </div>
        <HealthAccidentButtons
          yesRoute={yesRoute}
          noRoute={noRoute}
          title={strings!.pages.healthAccident.needYouRepatriated}
        />
      </Container>
    </>
  );
};
