import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
import { AppContext, ReadItems } from "src/contexts/appContext";
import { version } from "../../../../package.json";
import localforage from "localforage";
import { localForageKeys } from "src/helpers/localForageKey";
import { FetchApiStorage } from "src/services/FetchApi";
import { useMsal } from "@azure/msal-react";

export type InitLoaderProps = {};
export const InitLoader: FunctionComponent<InitLoaderProps> = () => {
  const { token, strings, api, setStrings, setUserInfo, updateHomeItems } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const msal = useMsal();
  useEffect(() => {
    if (!strings && token) {
      const cleanUpStorage = async () => {
        const keys = await localforage.keys();
        // iterater on key wo start with "/" (Because it's api call storage)
        // It's concern all api call who haven't a custom cacheKey config
        for (const i in keys.filter((key) => key.charAt(0) === "/")) {
          const itemKey = keys[i];
          const data = await localforage.getItem<FetchApiStorage<any>>(itemKey);

          // How many day we keep data after expiration
          const day = 20;
          const timeToDelete = day * 24 * 60 * 60 * 1000;
          if (data && data.expiredAt && data.expiredAt + timeToDelete < Date.now()) {
            localforage.removeItem(keys[i]);
          }
        }

        const readItemsStorage = await localforage.getItem<ReadItems>(localForageKeys.readItems);
        if (readItemsStorage) {
          for (let item in readItemsStorage) {
            // TODO : faire la gestion de version du storage dans un fichier a part
            if (readItemsStorage[item].filter) {
              // How many day we keep data after expiration
              const day = 12;
              const timeToDelete = day * 24 * 60 * 60 * 1000;
              const newTab = readItemsStorage[item].filter((entry) => {
                return Date.now() < entry.readAt + timeToDelete;
              });
              readItemsStorage[item] = newTab;
            } else {
              readItemsStorage[item] = [];
            }
          }
          localforage.setItem("readItems", readItemsStorage);
        }
      };
      const fetchInitialData = async () => {
        try {
          setUserInfo({
            email: msal.instance.getActiveAccount()?.username || '',
            firstName: msal.instance.getActiveAccount()?.name || '',
            lastName: "",
          })
          const homeItems = await api.static.getHomeItems();
          updateHomeItems(homeItems);
          const stringsData = await api.static.getAll();
          setStrings(stringsData);
          await localforage.setItem(localForageKeys.version, version);
          if (navigator.onLine) {
            cleanUpStorage();
          }
        } finally {
          setLoading(false);
        }
      };

      fetchInitialData();
    }
  }, [token]); // eslint-disable-line
  if (!token) {
    return (
      <>
        <LoadingScreen show />
      </>
    );
  }

  if (loading) return <LoadingScreen show />;

  return <></>;
};
