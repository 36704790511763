import React, { FunctionComponent, ReactChildren, ReactChild } from "react"; // importing FunctionComponent

export type TabProps = {
  label: string;
  children?: ReactChild | ReactChildren;
  onSelect?(): void;
  route?: string;
  active?: boolean;
};

export const Tab: FunctionComponent<TabProps> = () => {
  return <></>;
};
