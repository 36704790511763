import { FetchApi } from "./FetchApi";
export type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
};

export class UserApi extends FetchApi {

  async sendSuggestion(data: { message: string; email: string }) {
    return this.post(`/MySuggestion`, { message: data.message, myMail: data.email });
  }
  async sendCertificat(data: { myMail: string; message: string; file: any }) {
    return this.postFormData(`/services-rh`, this.objectToFormdata(data, ["file"]));
  }
}
