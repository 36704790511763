import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { loginRequest } from './msalConfig';

export const useAccessToken = () => {
  const { instance, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState<string>();
  const isFetching = useRef(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  const loginRedirect = useCallback(() => {
    const account = instance.getAllAccounts()[0];
    return instance.acquireTokenRedirect({
      ...loginRequest,
      account: account
    });
  }, [instance])

  const goToLogin = () => {
    setShouldLogin(true)
  }
  const acquireTokenSilent = async () => {
    const account = instance.getAllAccounts()[0];
    if (!account) {
      return false
    }
    const response = await instance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
    setAccessToken(response.accessToken)
    return true

  };

  const fetchAccessToken = async () => {
    console.log('Starting fetchAccessToken')
    await instance.initialize()
    const account = instance.getAllAccounts()[0];

    // Try to acquire token silently by refreshing the token cache
    if (!account) {
      console.log(`
        Aucun compte n'a été trouvé dans le local storage ou la session, 
        soit l'utilisateur ne s'est encore jamais connecté soit le local storage a mal été enregistré ou a été supprimé !
        --> Nous redirigeons donc l'utilisateur vers la page de connexion
      `)

      // THERE IS NO ACCOUNT WE CAN USE LOGIN REDIRECT
      return goToLogin();
    }
    console.log(`Un compte a bien été trouvé, msal va donc essayer de récupérer un token d'accès pour ce compte, Detail du compte:`, account)
    let error: any = undefined
    try {
      await acquireTokenSilent()
      return true
    } catch (e) {
      error = e
    }

    if (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // acquireTokenSilent fail due to interaction need, try with ssoSilent
        console.log(`
          L'erreur détectée est de type InteractionRequiredAuthError. Cela peut survenir dans plusieurs cas:
          1. L'utilisateur a plusieurs compte conncté et actif coté EntraID, il faut donc lui demander de choisir le compte à utiliser (via la page de connexion)
          2. L'utilisateur doit renouveler son consentement pour l'application
          3. L'utilisateur doit insérer son mot de passe (car la session a expiré coté entra id) 
        `);
      } else {
        console.log(`Une erreur inattendue est survenue lors de la récupération du token via acquireTokenSilent`, error)
      }
    }

    try {
      await instance
        .ssoSilent({
          ...loginRequest,
        })
      console.log('SSO Silent a fonctionné')
      await acquireTokenSilent()
      return true
    } catch (e) {
      console.log('SSO Silent ou acquireTokenSilent ont retourné une erreur', e);
      console.log(`Aucune des méthodes n'a fonctionné, nous allons donc rediriger l'utilisateur vers la page de connexion`)
      // As a last resort, use an interactive method
      return goToLogin()
    }
  }

  useEffect(() => {
    console.log(inProgress)
    if (inProgress === InteractionStatus.None && !isFetching.current) {
      isFetching.current = true;
      fetchAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  return { accessToken, shouldLogin, loginRedirect };
};
