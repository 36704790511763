import React, { FunctionComponent, useEffect, useState } from "react";
import { Container } from "src/components/atoms/container/container";
import { ListNews } from "src/components/organisms/lists/listNews/listNews";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { ListItemSkeleton } from "src/components/atoms/skeleton/listItemSkeleton";
import { Page } from "src/layouts/page";
import { NewsEntreprise, OfferRH } from "src/types/ApiItems";
import { AppNewsType } from "src/types/dataTypes";
import { ErrorScreen } from "src/components/molecules/errorScreen/errorScreen";
import { ErrorCodes } from "src/helpers/enum";
export type NewsGenericPageProps = {
  title: string;
  back: any;
  dontMiss?: boolean;
  type: AppNewsType;
  get(): Promise<NewsEntreprise[] | OfferRH[]>;
};
export const NewsGenericPage: FunctionComponent<NewsGenericPageProps> = ({
  back,
  title,
  type,
  dontMiss,
  get,
}) => {
  const [news, setNews] = useState<NewsEntreprise[] | null>(null);
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    setError(false);
    get().then((result) => {
      if (dontMiss) {
        setNews(result.filter((item) => item.dontMiss === true));
      } else {
        setNews(result);
      }
    }).catch(e => {
      setError(true);
    });
  }, []); // eslint-disable-line
  return (
    <Page>
      <PageHeader back={back} title={title} />
      <Container small left>
        {error && <ErrorScreen code={ErrorCodes.NEWS_GET_FAIL} info="Erreur lors de la récupération des données" />}
        {!news && !error && <ListItemSkeleton numberItem={4} />}
        {news && <ListNews news={news} type={type} />}
      </Container>
    </Page>
  );
};
NewsGenericPage.defaultProps = {
  type: "company",
};
