import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { routes } from "src/AppRouter";
import { Container } from "src/components/atoms/container/container";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";

type IFrameProps = {
  url?: string;
  title?: string;
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const IFrame: React.FunctionComponent<IFrameProps> = ({ url, title }) => {
  let query = useQuery();
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageUrl, setPageUrl] = useState<string>("");
  const history = useHistory();
  useEffect(() => {
    const getQueryUrl = () => {
      const queryUrl = query.get("url");
      if (!queryUrl) {
        history.push(routes.home);
        return "";
      }
      // Check url host
      const urlItem = new URL(queryUrl);
      if (urlItem.host.replace("www.", "") !== "patek.com") {
        history.push(routes.home);
        return "";
      }
      return queryUrl;
    };
    const page = url ? url : getQueryUrl();
    const pageName = title ? title : query.get("title");
    setPageTitle(pageName ? pageName : "External link");
    setPageUrl(page);
  }, []); // eslint-disable-line
  return (
    <div className="iframe">
      <PageHeader title={pageTitle ? pageTitle : "External link"} back={routes.home} />
      {pageUrl && <iframe className="myIframe" title="External Link" src={pageUrl}></iframe>}
      {!pageUrl && <Container>Page not found</Container>}
    </div>
  );
};
