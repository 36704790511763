import React, { FunctionComponent } from "react";
import { Icon } from "../icon/icon";
import { List } from "../../organisms/lists/list/list";
import { MySkeleton } from "./mySkeleton";

export type ListItemSkeletonProps = {
  numberItem: number;
};

export const ListEventSkeleton: FunctionComponent<ListItemSkeletonProps> = ({ numberItem }) => {
  return (
    <List>
      {[...Array(numberItem)].map((_, index) => {
        return (
          <li className="event event--twodate" key={index}>
            <div className="img">
              {/* <div className="img twoDate"> */}
              <div>
                <MySkeleton type="text" width={36} height={17} />
                <MySkeleton type="text" width={36} height={17} />
              </div>
            </div>
            <div className="content">
              <div className="title">
                <div className="text">
                  <MySkeleton type="text" width={180} height={17} />
                  <MySkeleton type="text" width={90} height={17} />
                </div>
                <MySkeleton type="text" width={36} height={17} />
              </div>
              <div>
                <Icon name="time" />
                <div className="text">
                  <MySkeleton type="text" width={32} height={14} />
                </div>
                <Icon name="pin" />
                <div className="text">
                  <MySkeleton type="text" width={50} height={14} />
                </div>
              </div>
              <div className="bottom">
                <Icon name="calendarClean" />
                <div className="text">
                  <MySkeleton type="text" width={170} height={14} />
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </List>
  );
};
