import React, { FunctionComponent, useContext } from "react";
import { ContactBox } from "src/components/molecules/contactBox/contactBox";
import { Container } from "src/components/atoms/container/container";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { AppContext } from "src/contexts/appContext";
export type HealthAccidentNotAbsentProps = {
  back: string;
};
export const HealthAccidentNotAbsent: FunctionComponent<HealthAccidentNotAbsentProps> = ({
  back,
}) => {
  const { strings } = useContext(AppContext);
  return (
    <>
      <PageHeader title={strings!.pages.healthAccident.notAbsentTitle} back={back} />
      <Container>
        <ContactBox
          description={strings?.contact.insuranceSpecialist.urgencyTitle}
          btnText={strings?.contact.insuranceSpecialist.phoneLabel}
          btnIcon="phone"
          href={`tel:${strings?.contact.insuranceSpecialist.phoneNumber}`}
        />
      </Container>
    </>
  );
};
