import React, { FunctionComponent } from "react";
import { MySkeleton } from "./mySkeleton";

export type WeekCalendarSkeletonProps = {
  numberItem: number;
};

export const WeekCalendarSkeleton: FunctionComponent<WeekCalendarSkeletonProps> = ({
  numberItem,
}) => {
  return (
    <ul className="weekCalendar">
      {[...Array(numberItem)].map((_, index) => {
        return (
          <li className="dateCalendar" key={index}>
            <MySkeleton type="text" width={20} height={14} />
            <MySkeleton type="text" width={20} height={20} />
          </li>
        );
      })}
    </ul>
  );
};
