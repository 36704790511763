import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import localforage from "localforage";
import { version } from "../../package.json";
import merge from "lodash/merge";

export type FetchApiRequestOptionsParam = {
  defaultErrorCatch?: boolean;
  addionnalAxiosOptions?: AxiosRequestConfig;
  removeBase?: boolean;
  cache?: boolean;
  cacheDuration?: number;
  cacheKey?: string;
  validate?: (data: any) => boolean;
};
export type FetchApiRequestOptions = {
  defaultErrorCatch: boolean;
  addionnalAxiosOptions: AxiosRequestConfig;
  removeBase: boolean;
  cache: boolean;
  cacheDuration: number;
  cacheKey?: string;
  validate: (data: any) => boolean;
};
export type FetchApiStorage<T> = {
  data: T;
  expiredAt: number;
};
export type FetchApiStorageGet<T> = {
  data: T;
  expired: boolean;
};
export class FetchApi {
  baseUrl: string | undefined;
  axios: AxiosInstance;
  token: string;
  version: string;
  errorCallback: (e: AxiosError) => void;
  axiosOptions: any;
  defaultRequestOptions: FetchApiRequestOptions;
  constructor(errorCallback, axiosOptions) {
    this.baseUrl = process.env.REACT_APP_API_BASE
      ? process.env.REACT_APP_API_BASE
      : "http://localhost:3001";
    this.token = process.env.REACT_APP_API_TOKEN ? process.env.REACT_APP_API_TOKEN : "";
    this.axios = axios.create();
    this.version = version;
    this.axiosOptions = axiosOptions;
    this.errorCallback = errorCallback;

    this.defaultRequestOptions = {
      defaultErrorCatch: false,
      addionnalAxiosOptions: {},
      removeBase: false,
      cache: true,
      cacheDuration: 1 * 60 * 1000, // 1 minute
      validate: () => true,
    };
  }
  private merge(obj1, obj2) {
    return merge(Object.assign({}, obj1), Object.assign({}, obj2));
  }
  private options(options: FetchApiRequestOptionsParam): FetchApiRequestOptions {
    return this.merge(this.defaultRequestOptions, options);
  }
  protected get<T = any>(endpoint: string, fetchoptions?: FetchApiRequestOptionsParam): Promise<T> {
    const options = this.options(fetchoptions ? fetchoptions : {});
    const cacheKey = options.cacheKey ? options.cacheKey : endpoint;
    return new Promise(async (resolve, reject) => {
      const dataFromStorage = await this.getInStorage(cacheKey);
      if (!navigator.onLine) {
        resolve(dataFromStorage?.data);
      } else if (options.cache && dataFromStorage && !dataFromStorage.expired) {
        resolve(dataFromStorage.data);
      } else {
        const url = options!.removeBase ? endpoint : this.baseUrl + endpoint;
        this.axios
          .get(url, this.merge(this.axiosOptions, options!.addionnalAxiosOptions))
          .then((response) => {
            resolve(response.data);
            if (options.cache && options.validate(response.data)) {
              this.saveInStorage(cacheKey, response.data, options);
            }
          })
          .catch((e) => {
            if (options.defaultErrorCatch !== false) {
              this.errorCallback(e);
            } else {
              reject(e);
            }
          });
      }
    });
  }
  protected getAny<T = any>(url: string, fetchoptions?: FetchApiRequestOptionsParam): Promise<T> {
    return this.get(url, { ...fetchoptions, removeBase: true });
  }

  protected post<T = any>(
    endpoint: string,
    data: any,
    addionnalAxiosOptions?: AxiosRequestConfig
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + endpoint;
      this.axios
        .post(url, data, this.merge(this.axiosOptions, addionnalAxiosOptions))
        .then((response) => {
          const data = response.data ? response.data : response;
          resolve(data);
        })
        .catch((e) => {
          // this.errorCallback(e);
          reject(e);
        });
    });
  }

  protected put<T = any>(
    endpoint: string,
    data: any,
    addionnalAxiosOptions?: AxiosRequestConfig
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + endpoint;
      this.axios
        .put(url, data, this.merge(this.axiosOptions, addionnalAxiosOptions))
        .then((response) => {
          const data = response.data ? response.data : response;
          resolve(data);
        })
        .catch((e) => {
          // this.errorCallback(e);
          reject(e);
        });
    });
  }
  protected delete<T = any>(endpoint: string): Promise<T> {
    return new Promise((resolve, reject) => {
      const url = this.baseUrl + endpoint;
      this.axios
        .delete(url, this.axiosOptions)
        .then((response) => {
          const data = response.data ? response.data : response;
          resolve(data);
        })
        .catch((e) => {
          // this.errorCallback(e);
          reject(e);
        });
    });
  }
  protected putFormData<T = any>(endpoint: string, data: FormData): Promise<T> {
    return this.put(endpoint, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  protected postFormData<T = any>(endpoint: string, data: FormData): Promise<T> {
    return this.post(endpoint, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  protected objectToFormdata(item, fileskeys: string[] = []) {
    const formdata = new FormData();
    for (const key in item) {
      if (fileskeys.includes(key)) {
        item[key].forEach((element) => {
          formdata.append(key, element);
        });
      } else {
        formdata.append(key, item[key]);
      }
    }
    return formdata;
  }
  protected objectToQuery(params?: {}) {
    if (!params) return "";
    let paramsArray: string[] = [];
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        paramsArray.push(key + "=" + params[key]);
      }
    });
    return paramsArray.length ? "?" + paramsArray.join("&") : "";
  }
  private saveInStorage(key, value, options: FetchApiRequestOptions) {
    if (options.cache) {
      return localforage.setItem(key, {
        data: value,
        expiredAt: new Date().getTime() + options.cacheDuration,
      });
    } else {
      return Promise.resolve(true);
    }
  }
  private async getInStorage<T = any>(key): Promise<FetchApiStorageGet<T> | null> {
    const store = await localforage.getItem<FetchApiStorage<T>>(key);
    if (store && store.expiredAt) {
      const expired = new Date().getTime() >= store.expiredAt;
      return {
        data: store.data,
        expired,
      };
    }
    return null;
  }
  protected removeFromStorage(key) {
    return localforage.removeItem(key);
  }
  /**
   *
   * @param {number} durationInMinutes
   * @returns {string} query version parameter (p.ex ?v=1237417473)
   */
  cacheBuster(durationInMinutes) {
    const coeff = 1000 * 60 * durationInMinutes;
    const date = new Date();
    const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
    return "?v=" + rounded.getTime();
  }
}
