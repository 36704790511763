import React from "react";
import "./assets/scss/App.scss";
import { AppContextProvider } from "./contexts/appContext";
import AppRouter from "./AppRouter";
import { ToastWrapper } from "./components/atoms/toast/toastWrapper";
import { ErrorBoundary } from "./components/molecules/errorBoundary/errorBoundary";
import { ErrorPage } from "./page/_utils/errorPage/errorPage";
import { PatekMsalProvider } from "./helpers/msalConfig";
import { AuthGuard } from "./page/_utils/authGuard/AuthGuard";

function App() {
  return (
    <ErrorBoundary name="app">
      <PatekMsalProvider>
        <AuthGuard>
          <ErrorPage></ErrorPage>
          <AppContextProvider>
            <ToastWrapper />
            <AppRouter />
          </AppContextProvider>
        </AuthGuard>
      </PatekMsalProvider>
    </ErrorBoundary>
  );
}

export default App;
