import { NewsEntreprise, NewsDetail } from "src/types/ApiItems";
import { FetchApi } from "./FetchApi";

export class NewsApi extends FetchApi {
  companyGetAll() {
    return this.get<NewsEntreprise[]>(`/news-enterprise`);
  }
  companyGetNotDontMiss() {
    return this.get<NewsEntreprise[]>(`/news-enterprise/false`);
  }
  companyGetDontMiss() {
    return this.get<NewsEntreprise[]>(`/news-enterprise/true`);
  }
  companyGetById(id: number) {
    return this.get<NewsDetail>(`/news-enterprise/${id}`);
  }
  informationGetAll() {
    return this.get<NewsEntreprise[]>("/news-rh/informationRH");
  }
  informationGetById(id: number) {
    return this.get<NewsDetail>(`/news-rh/${id}`);
  }
  organisationGetAll() {
    return this.get<NewsEntreprise[]>("/news-rh/organisationEntreprise");
  }
  organisationGetById(id) {
    return this.get<NewsDetail>(`/news-rh/${id}`);
  }
  restaurantGetAll() {
    return this.get<NewsEntreprise[]>("/annonceRestaurant");
  }
  restaurantGetById(id: number) {
    return this.get<NewsDetail>(`/annonceRestaurant/${id}`);
  }
  sportGetAll() {
    return this.get<NewsEntreprise[]>(`/clubsports`);
  }
  sportGetById(id: number) {
    return this.get<NewsDetail>(`/clubsports/${id}`);
  }
  committeeGetAll() {
    return this.get<NewsEntreprise[]>(`/annonceCoPers`);
  }
  committeeGetById(id: number) {
    return this.get<NewsDetail>(`/annonceCoPers/${id}`);
  }
  delegationGetAll(){
    return this.get<NewsEntreprise[]>(`/delegationSyndicale`);
  }
  delegationGetById(id: number) {
    return this.get<NewsDetail>(`/delegationSyndicale/${id}`);
  }
}
