import React, { FunctionComponent } from "react";
import { SearchBar } from "src/components/atoms/searchBar/searchBar";
import { PageHeader } from "../pageHeader/pageHeader";
export type documentHeaderProps = {
  title: string;
  searchLabel: string;
  value?: string | number;
  onChange: any;
  back: string;
  loading?: boolean,
};
export const DocumentHeader: FunctionComponent<documentHeaderProps> = ({
  title,
  searchLabel,
  value,
  onChange,
  children,
  back,
  loading
}) => {
  return (
    <>
      <PageHeader title={title} back={back}>
        <SearchBar disabled={loading} name={searchLabel} value={value} setValue={(e) => onChange(e)} />
      </PageHeader>
      {children}
    </>
  );
};

DocumentHeader.defaultProps = {
  title: "Documents importants",
};
