import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NewsDetailPage } from "src/components/organisms/newsDetailPage/newsDetailPage";
import { DetailsSkeleton } from "src/components/atoms/skeleton/detailsSkeleton";
import { AppContext } from "src/contexts/appContext";
import { Page } from "src/layouts/page";
import { NewsDetail, OfferDetail } from "src/types/ApiItems";
import { AppNewsType } from "src/types/dataTypes";
import { HrSingleJob } from "../hrSingle/hrSingleJob/hrSingleJob";
import { BasicPage } from "src/components/organisms/basicPage/basicPage";
export type NewsDetailGenericPageProps = {
  type: AppNewsType;
  title: string;
  back: string;
  get(id: number): Promise<NewsDetail | OfferDetail>;
};
export const NewsDetailGenericPage: FunctionComponent<NewsDetailGenericPageProps> = ({
  type,
  title,
  back,
  get,
}) => {
  const [news, setNews] = useState<NewsDetail | OfferDetail | null>(null);
  const [error,setError] = useState("");
  const { setReadItem } = useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    get(id).then(async (result) => 
    {
      if(result){
        setNews(result);
        setError("")
        if (result.isNew) {
          setReadItem(type, id);
        }
      }else{
        setError("Erreur lors de la récupération du détail")
      }
    }).catch(e=>{
      setError("Erreur lors de la récupération du détail")
    });
  }, []); // eslint-disable-line
  if(error){
    return   <BasicPage title={title} back={back}>
      {error}
    </BasicPage>
  }
  if (!news)
    return (
      <Page pageClass="layout__detail">
        <DetailsSkeleton title={title} withImage />
      </Page>
    );
  if (type === "job") return <HrSingleJob offer={news as OfferDetail} />;
  return <NewsDetailPage news={news as NewsDetail} type={type} title={title} back={back} />;
};
NewsDetailGenericPage.defaultProps = {
  type: "company",
};
