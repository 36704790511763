import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";

type ToggleButtonProps = {
  /** Color of button */
  value: boolean;
  setValue: any;
};

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
  children,
  value,
  setValue,
  ...modifiers
}) => {
  return (
    <label className={bemModifier(modifiers, "toggleButton")}>
      <input type="checkbox" onChange={() => setValue(!value)} checked={value} />
      <span className="slider"></span>
    </label>
  );
};
