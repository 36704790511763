import React, { FunctionComponent, useMemo } from "react";
import { getRoute, routes } from "src/AppRouter";
import { SmallAnnounceCategoryLabel, SmallAnnounceStatusEnum } from "src/helpers/enum";
import { formatPrice } from "src/helpers/miscHelpers";
import { Announce } from "src/types/ApiItems";
import { ListItem } from "../listItem/listItem";

export type AnnounceItemProps = {
  announce: Announce;
};

export const AnnounceItem: FunctionComponent<AnnounceItemProps> = ({ announce }) => {
  const price = useMemo(() => {
    return formatPrice(announce.price);
  }, []); // eslint-disable-line
  const label = (): string | undefined => {
    if (announce.status !== 0 && announce.isMine) {
      switch (SmallAnnounceStatusEnum[announce.status]) {
        case "Published":
          return "publiée";
        case "Awaiting":
          return "en attente";
        case "Refused":
          return "refusée";
        default:
          return "périmée";
      }
    } else if (announce.isNew) {
      return "new";
    } else {
      return undefined;
    }
  };
  return (
    <ListItem
      overtitle={SmallAnnounceCategoryLabel[announce.category]}
      title={announce.title}
      imgId={announce.imageUrl}
      // imgUrl={announce.imageUrl} TODO
      tag={price}
      newLabel={label()}
      date={announce.publishingDate ? announce.publishingDate : undefined}
      link={getRoute(routes.announcesDetail, { id: announce.id })}
      fade={announce.status === 4 ? true : false}
      key={announce.id}
    />
  );
};
