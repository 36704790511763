import { File } from "src/types/ApiItems";

// Found in https://stackoverflow.com/a/34792287
export const listToTree = (data): Array<File> => {
  const ID_KEY = "id";
  const PARENT_KEY = "parentId";
  const CHILDREN_KEY = "children";
  let tree: Array<File> = [],
    childrenOf = {};
  let item, id, parentId;

  for (const element of data) {
    item = element;
    id = item[ID_KEY];

    parentId = item[PARENT_KEY] || 0;
    // every item may have children
    childrenOf[id] = childrenOf[id] || [];
    // init its children
    item[CHILDREN_KEY] = childrenOf[id];
    if (parentId !== 0) {
      // init its parent's children object
      childrenOf[parentId] = childrenOf[parentId] || [];
      // push it into its parent's children object
      childrenOf[parentId].push(item);
    } else {
      tree.push(item);
    }
  }

  return tree;
};
