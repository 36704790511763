import { get as _get } from "lodash"
import { listToTree } from "src/helpers/listToTree";
import { File } from "src/types/ApiItems";
import { FetchApi } from "./FetchApi";
import { BreadcrumbItem } from "src/components/molecules/breadcrumb/breadcrumb";
import { routes } from "src/AppRouter";
import { CustomError } from "src/helpers/CustomError";
import { ErrorCodes } from "src/helpers/enum";

export const findCurrentFilePath = (
  menu: File[],
  current: number,
  parent: string = ""
): string | false => {
  for (let i = 0; i < menu.length; i++) {
    const item = menu[i]
    if (item.id === current) {
      return `${parent}[${i}]`
    } else if (item.children && item.children.length) {
      const inChildren = findCurrentFilePath(
        item.children,
        current,
        `${parent}[${i}].children`
      )
      if (inChildren) {
        return inChildren
      }
    }
  }
  return false
}

export class DocumentReperesApi extends FetchApi {
  rootStructured: File[] = [];
  allItems: File[] = [];
  lastUpdated: File[] = [];
  isInit = false;
  async init() {
    if (!this.isInit) {
      this.allItems = await this.get<File[]>("/documentsReperes");
      if (!this.allItems.length || !this.allItems[0].id) {
        throw new CustomError(ErrorCodes.API_BAD_RESPONSE_FORMAT);
      }
      this.rootStructured = listToTree(this.allItems.slice());
      this.lastUpdated = this.allItems.slice().sort(
        (a, b) => {
          return b.publishedOn.localeCompare(a.publishedOn)
        }
      ).slice(0, 3);
      this.isInit = true
    }
  }
  async getAll() {
    await this.init();
    return this.allItems
  }
  async getRoot() {
    await this.init();
    return this.rootStructured;
  }
  async getLastUpdated() {
    await this.init();
    return this.lastUpdated;
  }
  async getFolderById(id: number): Promise<{ folder: File, breadcrumb: BreadcrumbItem[] }> {
    await this.init();
    const objPath = findCurrentFilePath(this.rootStructured, Number(id))
    if (objPath) {
      return {
        folder: _get(this.rootStructured, objPath),
        breadcrumb: this.getBreadcrumbByPath(objPath)
      }
    } else {
      throw new Error("Folder not found")
    }
  }
  /**
   * 
   * @param path string representation of object key p.ex ([0].children[4])
   * @returns an array with breadcrumb items
   */
  getBreadcrumbByPath(path: string): BreadcrumbItem[] {
    const items: BreadcrumbItem[] = []
    let completePath = ""
    path.split('.').forEach(pathPart => {
      completePath += pathPart;
      const item: File = _get(this.rootStructured, completePath);
      items.push({
        label: item.title,
        link: routes.documentFolder.replace(':id', item.id.toString())
      })
    })
    items[0] = {
      link: routes.documentsImportant,
      label: "Dossier Racine"
    }
    delete items[items.length - 1].link
    return items;
  }
  async getSearchResult(term: string) {
    await this.init();
    return this.allItems.filter((item) => {
      return [item.title, item.publishedBy].join("").toLowerCase().includes(term.toLowerCase());
    });
  }
}
