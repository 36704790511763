import React, { FunctionComponent, useContext, useState } from "react";
import { bemModifier } from "../../../helpers/bemModifier";
import { Text } from "../../atoms/text/text";
import { Icon, IconNames } from "src/components/atoms/icon/icon";
import { RouterLink } from "src/components/atoms/routerLink/routerLink";
import { LoadingScreen } from "src/components/molecules/loadingScreen/loadingScreen";
import { download } from "src/helpers/downloadFiles";
import { AppContext } from "src/contexts/appContext";

type ListItemFileProps = {
  overtitle: string;
  title: string;
  fileCount?: number;
  author?: string | null;
  icon: IconNames;
  link?: string | null;
  isFolder?: boolean;
  folderId?: number;
  onClick?: () => void;
  collapsible?: boolean;
};

export const ListItemFile: FunctionComponent<ListItemFileProps> = ({
  overtitle,
  title,
  fileCount,
  author,
  icon,
  link,
  onClick,
  isFolder,
  folderId,
  children,
  collapsible = true,
  ...modifiers
}) => {
  const { token } = useContext(AppContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const regExURLToPatekFiles = /https?:\/\/appemp[-a-zA-Z0-9@:%._+~#=]{0,12}\.patek\.[a-zA-Z0-9()]{1,6}\b\/api\/files\/([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
  const displayCount =
    fileCount && fileCount > 1 ? `${fileCount} Fichiers` : `${fileCount || 0} Fichier`;

  const contentItem = (
    <>
      <div className="listItemFile__meta">
        <Text type="subtitle">{overtitle}</Text>
      </div>
      <Text type="h5" className="listItemFile__title">
        {title}
      </Text>
      <Text type="subtitle">
        {displayCount && !author && displayCount}
        {author && author}
      </Text>
    </>
  );

  const Item = collapsible ? (
    <section className={`listItemFile__level`}>
      <div className={`listItemFile__icon`} onClick={onClick}>
        <Icon name={icon} />
      </div>
      <div className="listItemFile__content">
        {isFolder ? (
          <RouterLink to={`/documents/dossier/${folderId}`} transition="next">
            {contentItem}
          </RouterLink>
        ) : (
          contentItem
        )}
      </div>
    </section>
  ) : (
    <RouterLink
      to={`/documents/dossier/${folderId}`}
      transition="next"
      className={`listItemFile__level`}
    >
      <div className={`listItemFile__icon`}>
        <Icon name={icon} />
      </div>
      <div className="listItemFile__content">{isFolder ? <>{contentItem}</> : contentItem}</div>
    </RouterLink>
  );

  return (
    <>
      <div className={`${bemModifier(modifiers, "listItemFile")}`}>
        {link ? (
          <a
            href={link}
            onClick={
              regExURLToPatekFiles.test(link)
                ? (e) => {
                    e.preventDefault();
                    download(link, token, setShowModal);
                  }
                : undefined
            }
            title={title}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Item}
          </a>
        ) : (
          Item
        )}
      </div>
      <LoadingScreen show={showModal} message="Téléchargement des fichiers en cours" backdrop />
    </>
  );
};
ListItemFile.defaultProps = {
  // This value is adopted when name prop is omitted.
};
