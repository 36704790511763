import React from 'react';
import { Configuration, InteractionStatus, PopupRequest } from '@azure/msal-browser';

import {
  PublicClientApplication,
} from '@azure/msal-browser';
import { FC, ReactNode } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { LoadingScreen } from 'src/components/molecules/loadingScreen/loadingScreen';

const clientId = process.env.REACT_APP_MSAL_CLIENT_ID;
const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
const redirectUri = process.env.REACT_APP_MSAL_REDIRECT_URI;
const msalScopes = process.env.REACT_APP_MSAL_SCOPES;

if (!clientId || !tenantId || !redirectUri) {
  throw new Error('No MSAL_CLIENT_ID or MSAL_TENANT_ID or REDIRECT_URI provided');
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: msalScopes?.split(',') || [
    'User.Read',
  ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};



export const pca = new PublicClientApplication(msalConfig);

export const PatekMsalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <MsalProvider instance={pca}>
    <PatekAuthLoader>
      {children}
    </PatekAuthLoader>
  </MsalProvider>;
}
const PatekAuthLoader: FC = ({ children }) => {
  const msal = useMsal()
  console.log('msal', msal)
  if (msal.inProgress !== InteractionStatus.None) {
    <LoadingScreen show />
  }
  return <>{children}</>;
};