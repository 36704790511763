import React from "react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/contexts/appContext";
import { WeekCalendar } from "src/components/organisms/weekCalendar/weekCalendar";
import { Tabs } from "src/components/organisms/tabs/tabs";
import { Tab } from "src/components/organisms/tabs/tab";
import { ListRecipe } from "src/components/organisms/lists/listRecipe/listRecipe";
import { Page } from "src/layouts/page";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { routes } from "src/AppRouter";
import moment from "src/helpers/moment";
import { ListRecipeSkeleton } from "src/components/atoms/skeleton/listRecipeSkeleton";
import { WeekCalendarSkeleton } from "src/components/atoms/skeleton/weekCalendarSkeleton";
import localforage from "localforage";
import { WeekMenu } from "src/types/ApiItems";

export const RestaurantsMenu = () => {
  const { getHomeItemTitle } = useContext(AppContext);
  const today = (): number => {
    let day = parseInt(moment().format("E")) - 1;
    if (day > 4) {
      day = 0;
    }
    return day;
  };
  const saveInStorage = (key, value) => {
    return localforage.setItem(key, value);
  };
  const getInStorage = (key) => {
    return localforage.getItem(key);
  };
  const [weekMenu, setWeekMenu] = useState<WeekMenu[] | null>(null);
  const [actual, setActual] = useState<number>(today);
  const [resto, setResto] = useState<any>("PLO");
  const { api } = useContext(AppContext);
  useEffect(() => {
    api.menus.getWeekMenu().then((result) => {
      setWeekMenu(result);
    });
    getInStorage("restoClicked").then((data) => {
      if (data) {
        setResto(data);
      }
    });
  }, []); // eslint-disable-line

  return (
    <Page pageClass="restaurantsMenu">
      <PageHeader flat title={getHomeItemTitle("restaurant")} back={routes.home} />
      <div className="content">
        {weekMenu ? (
          <>
            <WeekCalendar days={weekMenu} actual={actual} setActual={setActual} />
            <Tabs router key={actual}>
              <Tab
                label="PLO"
                route={routes.restaurantOne}
                onSelect={() => saveInStorage("restoClicked", "PLO")}
                active={resto === "PLO" ? true : false}
              >
                <ListRecipe
                  restaurant={weekMenu[actual].restaurants.find((r) => r.name === "PLO")}
                />
              </Tab>
              <Tab
                label="PP6"
                route={routes.restaurantTwo}
                onSelect={() => saveInStorage("restoClicked", "PP6")}
                active={resto === "PP6" ? true : false}
              >
                <ListRecipe
                  restaurant={weekMenu[actual].restaurants.find((r) => r.name === "PP6")}
                />
              </Tab>
            </Tabs>
          </>
        ) : (
          <>
            <WeekCalendarSkeleton numberItem={5} />
            <Tabs router key={weekMenu}>
              <Tab label="PLO / PLY" route={routes.restaurantDefault}>
                <ListRecipeSkeleton numberItem={3} />
              </Tab>
              <Tab label="PP5" route={routes.restaurantTwo}>
                <ListRecipeSkeleton numberItem={3} />
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    </Page>
  );
};
