import { ErrorCodes } from "./enum";
type CustomErrorData = {
    message?: string,
    info?: string
}
export class CustomError {
    public message?: string;
    public info?: string;
    constructor(public code: ErrorCodes, data?: CustomErrorData) {
        if (data) {
            this.message = data.message;
            this.info = data.info;
        }

    }
}