import React, { FunctionComponent, useContext } from "react";
import { routes } from "src/AppRouter";
import { ContactBox } from "src/components/molecules/contactBox/contactBox";
import { Container } from "src/components/atoms/container/container";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Text } from "src/components/atoms/text/text";
import { AppContext } from "src/contexts/appContext";
export type HealthAccidentAbsentProps = {
  back: string;
  hasConsulted: boolean | null;
  hideIntro?: boolean
};
export const HealthAccidentAbsent: FunctionComponent<HealthAccidentAbsentProps> = ({ back, hasConsulted, hideIntro = false }) => {
  const { strings } = useContext(AppContext);
  return (
    <>
      <PageHeader title={strings!.pages.healthAccident.absentTitle} back={back} />
      <Container>
        {hasConsulted && !hideIntro && (
          <Text type="intro">{strings?.pages.healthAccident.repatriatedIntro}</Text>
        )}
        {!hasConsulted && !hideIntro && <Text type="intro">{strings?.pages.healthAccident.needToConsult}</Text>}
        <ContactBox
          title={strings?.contact.central.urgencyTitle}
          href={`tel:${strings?.contact.central.phoneNumber}`}
          badge={1}
          btnText={strings?.contact.central.phoneLabel}
          btnIcon="phone"
        />
        <ContactBox
          title={strings?.contact.rh.urgencyTitle}
          href={`tel:${strings?.contact.rh.phoneNumber}`}
          badge={2}
          btnText={strings?.contact.rh.phoneLabel}
          btnIcon="phone"
        />
        <ContactBox
          title={strings?.pages.healthAccident.certificatContactTitle}
          link={routes.serviceRh}
          badge={3}
          btnText={strings?.pages.healthAccident.certificatBtnLabel}
          btnIcon="mail"
        />
        <ContactBox
          badge={4}
          description={strings?.contact.insuranceSpecialist.urgencyTitle}
          btnText={strings?.contact.insuranceSpecialist.phoneLabel}
          href={`tel:${strings?.contact.insuranceSpecialist.phoneNumber}`}
          btnIcon="phone"
        />
      </Container>
    </>
  );
};
