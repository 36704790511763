import React, { FunctionComponent } from "react";
import { Link } from "react-tiger-transition";

export type RouterLinkProps = {
  to?: string;
  transition?: "next" | "back";
  className?: string;
};
export const RouterLink: FunctionComponent<RouterLinkProps> = ({
  to,
  transition,
  children,
  className,
}) => {
  const transitions = {
    next: "glide-left",
    back: "glide-right",
  };
  const transitionName = transition ? transitions[transition] : "";
  return (
    <Link className={className} to={to} transition={transitionName}>
      {children}
    </Link>
  );
};
RouterLink.defaultProps = {
  transition: "next", // This value is adopted when name prop is omitted.
};
