import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { Icon } from "src/components/atoms/icon/icon";
import { Text } from "src/components/atoms/text/text";
import { Breadcrumb, BreadcrumbItem } from "src/components/molecules/breadcrumb/breadcrumb";
import { bemModifier } from "src/helpers/bemModifier";

export type HeaderDocumentProps = {
  title: string;
  fileCount?: number;
  date: string;
  size: string;
  items: BreadcrumbItem[];
};

export const HeaderDocument: FunctionComponent<HeaderDocumentProps> = ({
  title,
  fileCount,
  date,
  size,
  items,
  ...modifiers
}) => {
  return (
    <header className={bemModifier(modifiers, "header-document")}>
      <Breadcrumb items={items} />
      <div className="header-document__wrapper">
        <div className={`header-document__icon`}>
          <Icon name="document" />
        </div>
        <div className="header-document__content">
          <Text type="h2" className="header-document__title">
            {title}
          </Text>
          <Text type="subtitle">
            {fileCount} Fichiers | {date} | {size}
          </Text>
        </div>
      </div>
    </header>
  );
};

HeaderDocument.defaultProps = {
  items: [],
};
