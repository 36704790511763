import React, { useContext } from "react";
import { Text } from "src/components/atoms/text/text";
import { Page } from "src/layouts/page";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { AppContext } from "src/contexts/appContext";

export const UpgradeAdviceConfirmation = () => {
  const { getHomeItemTitle } = useContext(AppContext);
  return (
    <Page pageClass="upgradeAdvice">
      <PageHeader back={routes.home} title={getHomeItemTitle("upgradeAdvice")} />
      <Container>
        <Text type="h2">Message bien reçu ! Nous prendrons en compte votre retour.</Text>
      </Container>
    </Page>
  );
};
