import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Icon } from "../icon/icon";
import { Text } from "../text/text";

type DropdownProps = {
  /** Color of button */
  name: string;
  value: string;
  setValue: any;
  options: {};
  error?: any;
};

export const Dropdown: FunctionComponent<DropdownProps> = ({
  children,
  name,
  value,
  setValue,
  options,
  error,
  ...modifiers
}) => {
  const listOptions = Object.keys(options).map((key, index) => {
    const label = options[key];
    return (
      <option key={index} value={key}>
        {label}
      </option>
    );
  });
  return (
    <>
      <div className={bemModifier(modifiers, "dropdown")}>
        <Icon name="chevronDown" />
        <select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={name}
          name={name}
          required
        >
          <option value="" disabled hidden></option>
          {listOptions}
        </select>
        <label htmlFor={name}>{name}</label>
      </div>
      {error && (
        <Text type="p3" color="orange">
          {error}
        </Text>
      )}
    </>
  );
};
