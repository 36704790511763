import * as React from "react";
import { Page } from "src/layouts/page";
import { Text } from "src/components/atoms/text/text";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/contexts/appContext";
import { useParams, useHistory } from "react-router-dom";
import { HeroSlider } from "src/components/molecules/heroSlider/heroSlider";
import { Icon } from "src/components/atoms/icon/icon";
import { Badge } from "src/components/atoms/badge/badge";
import { Button } from "src/components/atoms/button/button";
import { Container } from "src/components/atoms/container/container";
import { getRoute, routes } from "src/AppRouter";
import moment from "src/helpers/moment";
import { Wysiwyg } from "src/components/atoms/wysiwyg/wysiwyg";
import { DetailsSkeleton } from "src/components/atoms/skeleton/detailsSkeleton";
import { AnnounceDetail } from "src/types/ApiItems";
import { ConfirmModal } from "src/components/molecules/confirmModal/confirmModal";
import { formatPrice } from "src/helpers/miscHelpers";
import { SmallAnnounceCategoryLabel } from "src/helpers/enum";

export const SmallAnnounceDetail = () => {
  const [announce, setAnnounce] = useState<AnnounceDetail | null>(null);
  const [publishDate, setPublishDate] = useState<string>();
  const { api, toast } = useContext(AppContext);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { id } = useParams();
  const history = useHistory();
  const remove = () => {
    api.smallAnnounces
      .remove(id)
      .then(() => {
        setShowConfirm(false);
        toast.success("Annonce supprimé avec succès");
        history.push(routes.announcesMine);
      })
      .catch(() => {
        toast.createUnknkowError();
      });
  };
  useEffect(() => {
    api.smallAnnounces.getAnnounce(id).then((result) => {
      setAnnounce(result);
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setPublishDate(moment(announce?.publishingDate).format("DD MMMM YYYY"));
  }, [announce]);

  const updateButton = (
    <Button color="secondary" large outline link={getRoute(routes.announcesUpdate, { id: id })}>
      modifier
    </Button>
  );
  return (
    <Page pageClass="layout__detail announceDetail">
      {announce ? (
        <>
          <HeroSlider
            href={announce.isMine ? routes.announcesMine : routes.announces}
            title="Petites annonces"
            color="white"
            images={announce.images}
            badge={announce.isNew ? "new" : undefined}
          ></HeroSlider>
          {announce.isMine && (
            <div className="updateRemove">
              <div className="yesno">
                {updateButton}
                <Button large onClick={() => setShowConfirm(true)}>
                  supprimer
                </Button>
              </div>
            </div>
          )}
          <Container>
            <div className="flexBetween">
              <Text type="p4">{SmallAnnounceCategoryLabel[announce.category]}</Text>
              <Badge>{formatPrice(announce.price)}</Badge>
            </div>

            <Text type="h1">{announce.title}</Text>
            {announce?.publishingDate && (
              <div className="publish">
                <Icon name="time" />
                <Text type="p3" color="paleBrown">{`Publié le ${publishDate}`}</Text>
              </div>
            )}

            {/* TODO: est-ce vraiment un wysiwyg ??? */}
            <Wysiwyg content={announce.description} />

            {announce.displayContact && (
              <div className="box">
                <Text type="subtitle">auteur</Text>
                <Text type="h4">{announce.publishing.contact.vendorName}</Text>
                {announce.publishing.contact.vendorPhoneNumber && (
                  <Button
                    icon="phone"
                    ahref={`tel:${announce.publishing.contact.vendorPhoneNumber}`}
                  >
                    Appeler au {announce.publishing.contact.vendorPhoneNumber}
                  </Button>
                )}
                {announce.publishing.contact.vendorEmail && (
                  <Button
                    icon="mail"
                    color="secondary"
                    ahref={`mailto:${announce.publishing.contact.vendorEmail}`}
                  >
                    Envoyer un e-mail
                  </Button>
                )}
              </div>
            )}
          </Container>
          <ConfirmModal
            show={showConfirm}
            title="Confirmation"
            onConfirm={remove}
            onCancel={() => setShowConfirm(false)}
            confirmLabel="Supprimer"
          >
            Êtes vous sure de vouloir supprimer cette annonce ?
          </ConfirmModal>
        </>
      ) : (
        <DetailsSkeleton title="Petites annonces" withImage />
      )}
    </Page>
  );
};
