import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";
import { Icon, IconNames } from "../icon/icon";
import { RouterLink } from "../routerLink/routerLink";

type ButtonProps = {
  /** Color of button */
  icon?: IconNames;
  color?: "primary" | "secondary" | "white"; // Change the required prop to an optional prop.
  outline?: boolean;
  large?: boolean;
  small?: boolean;
  transitionBack?: boolean;
  ahref?: string;
  link?: string | null;
  target?: "_blank";
  className?: string;
  onClick?();
};

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  ahref,
  target,
  link,
  icon,
  transitionBack,
  className,
  onClick,
  ...modifiers
}) => {
  return (
    <button
      className={bemModifier(modifiers, "button") + ` ${className || ''}`}
      onClick={onClick ? onClick : undefined}
    >
      {icon && <Icon name={icon} color="white"></Icon>}
      {ahref && (
        <a href={ahref} target={target}>
          {ahref}
        </a>
      )}
      {link && <RouterLink to={link} transition={transitionBack ? "back" : "next"} />}
      {children}
    </button>
  );
};
Button.defaultProps = {
  color: "primary", // This value is adopted when name prop is omitted.
};
