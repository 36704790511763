import * as React from "react";
import { Page } from "src/layouts/page";
import { Text } from "src/components/atoms/text/text";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";

export const RhServiceConfirmation = () => {
  return (
    <Page>
      <PageHeader back={routes.health} title="Service RH" />
      <Container>
        <div className="rhService">
          <Text type="h4">Demande bien envoyée !</Text>
        </div>
      </Container>
    </Page>
  );
};
