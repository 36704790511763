import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { AppContext } from "src/contexts/appContext";
import { Toast, ToastProps } from "./toast";
import { nanoid } from "nanoid";
export type ToastWrapperProps = {};
type ToastItem = ToastProps & {
  uuid: string;
};
export const ToastWrapper: FunctionComponent<ToastWrapperProps> = () => {
  const { toast } = useContext(AppContext);
  const [toasts, setToasts] = useState<ToastItem[]>([]);
  const removeToast = (toRemove) => {
    setToasts((oldToasts) => {
      return oldToasts.filter((toastItem) => toastItem.uuid !== toRemove.uuid);
    });
  };
  useEffect(() => {
    toast.on((toastData) => {
      setToasts((oldToasts) => {
        return [...oldToasts, { ...toastData, uuid: nanoid() }];
      });
    });
  }, []); // eslint-disable-line
  return (
    <div className="toast-wrapper">
      {toasts.map((toastItem, key) => (
        <Toast
          message={toastItem.message}
          type={toastItem.type}
          key={toastItem.uuid}
          onRemove={() => removeToast(toastItem)}
        />
      ))}
    </div>
  );
};
