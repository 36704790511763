import React, { useContext } from "react";

import { Page } from "src/layouts/page";
import { ButtonIcon } from "src/components/molecules/buttonIcon/buttonIcon";
import { Button } from "src/components/atoms/button/button";
import { PageHeader } from "src/components/molecules/pageHeader/pageHeader";
import { Container } from "src/components/atoms/container/container";
import { routes } from "src/AppRouter";
import { AppContext } from "src/contexts/appContext";
import { ErrorScreen } from "src/components/molecules/errorScreen/errorScreen";
export const HealthPage = () => {
  const { strings, getHomeItemTitle } = useContext(AppContext);
  try {
    return (
      <Page pageClass="healthPage">
        <PageHeader back={routes.home} title={getHomeItemTitle("health")} />

        <Container small>
          <div>
            <div className="healthPage__item">
              <ButtonIcon icon="phone" link={routes.healthAdvice} large>
                {strings?.pages.health.needHealthAdvice}
              </ButtonIcon>
            </div>
            <div className="healthPage__item">
              <ButtonIcon icon="health" link={routes.healthAbsence} large>
                {strings?.pages.health.youAreWeak}
              </ButtonIcon>
            </div>
            <div className="healthPage__item">
              <ButtonIcon icon="accident" link={routes.healthAccident} large>
                {strings?.pages.health.youGotAccident}
              </ButtonIcon>
            </div>
            <div className="healthPage__item">
              <ButtonIcon icon="phone" link={routes.trustPerson} large>
                {strings?.pages.health.trustPerson}
              </ButtonIcon>
            </div>
          </div>
          <div className="healthPage__cta">
            <Container>
              <Button icon="phone" large ahref={`${strings?.pages.health.callUrgencyNumber}`}>
                {strings?.pages.health.callUrgencyLabel}
              </Button>
            </Container>
          </div>
        </Container>
      </Page>
    );
  } catch (e) {
    console.error(e);
    return <ErrorScreen>Erreur lors du chargement de la page santé</ErrorScreen>;
  }
};
