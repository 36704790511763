import React from "react";
import { ErrorScreen } from "../errorScreen/errorScreen";
import localforage from "localforage";
import { localForageKeys } from "src/helpers/localForageKey";
import { ErrorCodes } from "src/helpers/enum";
type ErrorBoundaryProps = {
  // using `interface` is also ok
  name: string;
};
type ErrorBoundaryState = {
  hasError: boolean; // like this
  errorCode: ErrorCodes;
};
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorCode: ErrorCodes.UNKNOWN_ERROR_ROUTER };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }
  cleanBackHome() {
    setTimeout(() => {
      if (!this.state.hasError) {
        localforage.removeItem(localForageKeys.backHome);
      }
    }, 5000);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorScreen code={this.state.errorCode}>
          <div>Erreur inconnue lors du rendu de {this.props.name}</div>
          <div>
            <small>url: {window.location.href}</small>
          </div>
        </ErrorScreen>
      );
    }
    this.cleanBackHome();
    return this.props.children;
  }
}
