import axios from "axios";
import { saveAs } from "file-saver";

export const download = async (url: string | undefined, token: string, setModal) => {
  setModal(true);
  if (url) {
    let file;
    try {
      file = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status === 406;
        },
        responseType: "blob",
      });
    } catch (error) {
      console.log(error, "ERROR");
    }
    if (file.status === 406) {
      const fr = new FileReader();
      fr.onload = (e) => {
        // @ts-ignore
        alert(JSON.parse(e.target.result).message);
      };
      fr.readAsText(file.data);
    } else {
      let filename = file.headers["content-disposition"].split("=")[1];
      // Si le nom de fichier est entouré de " ou d'autre caractères spéciaux il faut les enlever
      // Vérifier si le premier caractère n'est pas une lettre ou un chiffre
      if (!/[a-zA-Z0-9]/.test(filename.charAt(0))) {
        filename = filename.slice(1); // Retirer le premier caractère
      }

      // Vérifier si le dernier caractère n'est pas une lettre ou un chiffre
      if (!/[a-zA-Z0-9]/.test(filename.charAt(filename.length - 1))) {
        filename = filename.slice(0, -1); // Retirer le dernier caractère
      }

      saveAs(file.data, filename);
    }
    setModal(false);
  }
};
