import React, { useContext } from "react";
import { Page } from "src/layouts/page";
import { HealthAccidentIndex } from "./healthAccidentIndex";
import { useEffect, useState } from "react";
import { HealthAccidentAction } from "./healthAccidentAction";
import { HealthAccidentActionDoctor } from "./healthAccidentActionDoctor";
import { getRoute, routes } from "src/AppRouter";
import { useParams } from "react-router-dom";
import { ContactBox } from "src/components/molecules/contactBox/contactBox";
import { Text } from "src/components/atoms/text/text";
import { HealthAccidentAbsent } from "./HealthAccidentAbsent";
import { HealthAccidentNotAbsent } from "./healthAccidentNotAbsent";
import { HealthAccidentNothingTodo } from "./healthAccidentNothingTodo";
import { useLocation } from "react-router-dom";
import { AppContext } from "src/contexts/appContext";

// prettier-ignore
export enum Steps {
  Index = 0,
    NotRepatriated = 1,
      NotRepatriedNotAbsent = 11,
        NotRepatriatedNotAbsentNoDoctor = 111,
        NotRepatriatedNotAbsentYesDoctor = 112,
      NotRepatriatedAbsent = 12,
        NotRepatriatedAbsentNoDoctor = 121,
        NotRepatriatedAbsentYesDoctor = 122,
    Repatriated = 2,
      RepatriatedNotAbsent= 21,
        RepatriatedNotAbsentNoDoctor = 211,
        RepatriatedNotAbsentYesDoctor = 212,
      RepatriatedAbsent = 22,
        RepatriatedAbsentNoDoctor = 221,
        RepatriatedAbsentYesDoctor = 222,
}
export const HealthAccident = () => {
  const params = useParams();
  const [step, setStep] = useState<number>(-1);
  const location = useLocation();
  const { strings } = useContext(AppContext);
  useEffect(() => {
    if (step === -1) {
      setStep(params.step);
    } else if (typeof params.step !== "undefined") {
      setStep(params.step);
    }
  }, [location]); // eslint-disable-line
  return (
    <Page pageClass={`healthAccident data-step-${step}`}>
      {(!step || Number(step) === Steps.Index) && (
        <HealthAccidentIndex
          yesRoute={getRoute(routes.healthAccidentStep, { step: Steps.Repatriated })}
          noRoute={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriated })}
        />
      )}

      {Number(step) === Steps.NotRepatriated && (
        <HealthAccidentAction
          title={strings!.pages.healthAccident.notRepatriatedTitle}
          back={getRoute(routes.healthAccident)}
          yesRoute={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriedNotAbsent })}
          noRoute={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriatedAbsent })}
        />
      )}

      {Number(step) === Steps.NotRepatriatedAbsent && (
        <HealthAccidentActionDoctor
          title={strings!.pages.healthAccident.notRepatriatedTitle}
          back={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriated })}
          yesRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.NotRepatriatedNotAbsentYesDoctor,
          })}
          noRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.NotRepatriatedNotAbsentNoDoctor,
          })}
        />
      )}

      {Number(step) === Steps.NotRepatriedNotAbsent && (
        <HealthAccidentActionDoctor
          title={strings!.pages.healthAccident.doctorAbsentTitle}
          back={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriated })}
          yesRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.NotRepatriatedAbsentYesDoctor,
          })}
          noRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.NotRepatriatedAbsentNoDoctor,
          })}
        />
      )}

      {Number(step) === Steps.NotRepatriatedAbsentNoDoctor && (
        <HealthAccidentAbsent
          hasConsulted={false}
          back={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriedNotAbsent })}
        />
      )}
      {Number(step) === Steps.NotRepatriatedNotAbsentNoDoctor && (
        <HealthAccidentNothingTodo
          back={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriatedAbsent })}
        />
      )}
      {Number(step) === Steps.NotRepatriatedAbsentYesDoctor && (
        <HealthAccidentAbsent
          hasConsulted={true}
          back={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriedNotAbsent })}
        />
      )}
      {Number(step) === Steps.NotRepatriatedNotAbsentYesDoctor && (
        <HealthAccidentNotAbsent
          back={getRoute(routes.healthAccidentStep, { step: Steps.NotRepatriatedAbsent })}
        />
      )}
      {Number(step) === Steps.Repatriated && (
        <HealthAccidentAction
          title={strings!.pages.healthAccident.repatriatedTitle}
          back={getRoute(routes.healthAccident)}
          yesRoute={getRoute(routes.healthAccidentStep, { step: Steps.RepatriatedAbsent })}
          noRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.RepatriatedNotAbsent,
          })}
        >
          <Text type="intro">{strings?.pages.healthAccident.repatriatedIntro}</Text>
          <ContactBox
            title={strings?.contact.internationalSos.title}
            subtitle={strings?.contact.internationalSos.subtitle}
            btnText={strings?.contact.internationalSos.phoneLabel}
            btnIcon="phone"
            href={`tel:${strings?.contact.internationalSos.phoneNumber}`}
          />
        </HealthAccidentAction>
      )}
      {Number(step) === Steps.RepatriatedAbsent && (
        <HealthAccidentActionDoctor
          title={strings!.pages.healthAccident.repatriatedTitle}
          back={getRoute(routes.healthAccident)}
          yesRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.RepatriatedAbsentYesDoctor,
          })}
          noRoute={getRoute(routes.healthAccidentStep, { step: Steps.RepatriatedAbsentNoDoctor })}
        ></HealthAccidentActionDoctor>
      )}
      {Number(step) === Steps.RepatriatedNotAbsent && (
        <HealthAccidentActionDoctor
          title={strings!.pages.healthAccident.repatriatedTitle}
          back={getRoute(routes.healthAccident)}
          yesRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.RepatriatedNotAbsentYesDoctor,
          })}
          noRoute={getRoute(routes.healthAccidentStep, {
            step: Steps.RepatriatedNotAbsentNoDoctor,
          })}
        ></HealthAccidentActionDoctor>
      )}
      {Number(step) === Steps.RepatriatedAbsentYesDoctor && (
        <HealthAccidentAbsent
          hasConsulted={true}
          hideIntro={true}
          back={getRoute(routes.healthAccidentStep, { step: Steps.RepatriatedAbsent })}
        />
      )}
      {Number(step) === Steps.RepatriatedAbsentNoDoctor && (
        <HealthAccidentAbsent
          hasConsulted={false}
          back={getRoute(routes.healthAccidentStep, { step: Steps.RepatriatedAbsent })}
        />
      )}
      {Number(step) === Steps.RepatriatedNotAbsentNoDoctor && (
        <HealthAccidentAbsent
          hasConsulted={false}
          back={getRoute(routes.healthAccidentStep, { step: Steps.RepatriatedNotAbsent })}
        />
      )}
      {Number(step) === Steps.RepatriatedNotAbsentYesDoctor && (
        <HealthAccidentNotAbsent
          back={getRoute(routes.healthAccidentStep, { step: Steps.RepatriatedNotAbsent })}
        />
      )}
    </Page>
  );
};
