import React, { FunctionComponent } from "react"
import { Container } from "src/components/atoms/container/container";
import { Hero } from "src/components/molecules/hero/hero";
import { Page } from "src/layouts/page";

export type BasicPageProps = {
    title: string;
    back: string;
}

export const BasicPage : FunctionComponent<BasicPageProps> = ({title, back, children}) => {
    return <Page pageClass="">
    <Hero
      href={back}
      title={title}
    ></Hero>
      <Container>
        {children}
      </Container>
    </Page>
}