import React, { FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isRoute, routes } from "src/AppRouter";
import { Steps } from "src/page/health/healthAccident/healthAccident";
import { Icon } from "../../atoms/icon/icon";
import { RouterLink } from "../../atoms/routerLink/routerLink";

export const HomeBtn: FunctionComponent<{}> = () => {
  const [homeBtnUp, setHomeBtnUp] = useState<number>(0);
  const [hidden, setHidden] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    setHidden(false);
    if (isRoute(routes.home, location.pathname)) {
      setHidden(true);
    } else if (isRoute(routes.health, location.pathname)) {
      setHomeBtnUp(70);
    } else if (isRoute(routes.healthAccident, location.pathname)) {
      setHomeBtnUp(130);
    } else if (isRoute(routes.healthAccidentStep, location.pathname)) {
      let number = location.pathname.split("/");
      number = Number(number[number.length - 1]);
      setHomeBtnUp(
        [
          Steps.NotRepatriated,
          Steps.Repatriated,
          Steps.NotRepatriatedAbsent,
          Steps.NotRepatriedNotAbsent,
          Steps.RepatriatedAbsent,
        ].includes(number)
          ? 130
          : 0
      );
    } else {
      setHomeBtnUp(0);
    }
  }, [location]);
  return (
    <div
      id="homeButton"
      style={{ transform: `translateY(-${homeBtnUp}px)` }}
      className={hidden ? "hide" : ""}
    >
      <Icon name="home" />
      <RouterLink to={routes.home} transition={"next"} />
    </div>
  );
};
