import React, { FunctionComponent } from "react"; // importing FunctionComponent
import { bemModifier } from "../../../helpers/bemModifier";

type TextProps = {
  /** Color of button */
  type?:
    | "subtitle"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "p1"
    | "p2"
    | "p3"
    | "p4"
    | "link"
    | "label"
    | "intro";
  color?: "orange" | "paleBrown" | "white" | "black" | "";
  className?: string;
  center?: boolean;
};

export const Text: FunctionComponent<TextProps> = ({ children, className, ...modifiers }) => {
  if (children) {
    return <p className={bemModifier(modifiers, "text") + " " + className}>{children}</p>;
  } else {
    return null;
  }
};
