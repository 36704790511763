import * as React from "react";
import { useEffect, useState } from "react";
import { PatekApi } from "src/services/PatekApi";
import localforage from "localforage";
import { localForageKeys } from "src/helpers/localForageKey";
import { AppNewsType, StaticData } from "src/types/dataTypes";
import { version } from "../../package.json";
import { ToastHandler } from "src/components/atoms/toast/toastHandler";
import { UserInfo } from "src/services/UserApi";
import { routes } from "src/AppRouter";
import { IconNames } from "src/components/atoms/icon/icon";
import { useAccessToken } from "src/helpers/useAccessToken";
export type AppContextConstant = {
  api: PatekApi;
  version: string;
  toast: ToastHandler;
};
type readItem = {
  id: number;
  readAt: number;
};
export type Test = "event";
export type ReadItems = {
  [key in AppNewsType]: Array<readItem>;
} & {
  event: Array<readItem>;
};

export type ReadItemsKey = keyof ReadItems;

export type AppContextType = AppContextConstant & AppcontextState;
export type ListHomeType = "external" | "iframe" | "internal";

export type HomeItemId =
  | "newsDontMiss"
  | "newsCompany"
  | "events"
  | "newsOrganisation"
  | "job"
  | "newsInformation"
  | "collection"
  | "newsCommittee"
  | "newsdelegation"
  | "newsSport"
  | "restaurant"
  | "newsRestaurant"
  | "announces"
  | "health"
  | "documentsImportant"
  | "upgradeAdvice";

export type ListHomeItem = {
  id?: HomeItemId;
  link: string;
  icon: IconNames;
  title: string;
  notif?: number;
  type?: ListHomeType; // if not set it's internal link
};

export type ListHomeItemJson = {
  id?: HomeItemId;
  link?: string;
  icon?: IconNames;
  title?: string;
  notif?: number;
  type?: ListHomeType;
};

export type AppcontextState = {
  isOnline: boolean;
  strings?: StaticData;
  token?: string;
  homeItems: ListHomeItem[];
  updateHomeItems: (items: ListHomeItemJson[]) => void;
  getHomeItemTitle: (id: HomeItemId) => string;
  setIsOnline: (isOnline: boolean) => void;
  readItems: ReadItems;
  setReadItem: (key: ReadItemsKey, id: number) => void;
  isRead: (key: ReadItemsKey, id: number) => boolean;
  setStrings: (strings: StaticData) => void;
  userInfo?: UserInfo;
  setUserInfo(userInfo: UserInfo);
};

const defaultHomeItems: ListHomeItem[] = [
  {
    id: "newsDontMiss",
    link: routes.newsDontMiss,
    icon: "favorite",
    title: "À ne pas manquer",
  },
  {
    id: "newsCompany",
    link: routes.newsCompany,
    icon: "actus",
    title: "Patek News",
  },
  {
    id: "events",
    link: routes.events,
    icon: "calendar",
    title: "Événements à venir",
  },
  {
    id: "newsOrganisation",
    link: routes.newsOrganisation,
    icon: "orga",
    title: "Organisation de l'entreprise",
  },
  {
    id: "job",
    link: routes.job,
    icon: "searchJob",
    title: "Nous recherchons",
  },
  {
    id: "newsInformation",
    link: routes.newsInformation,
    icon: "contact",
    title: "Informations RH",
  },
  {
    id: "collection",
    link: routes.collection,
    icon: "watch",
    title: "Collection",
  },
  {
    id: "newsCommittee",
    link: routes.newsCommittee,
    icon: "message",
    title: "Commission du personnel",
  },
  {
    id: "newsdelegation",
    link: routes.delegationSyndicale,
    icon: "handshake",
    title: "Délégation Syndicale",
  },
  {
    id: "newsSport",
    link: routes.newsSport,
    icon: "sport",
    title: "Club de sport",
  },
  {
    id: "restaurant",
    link: routes.restaurantDefault,
    icon: "food",
    title: "Menu des restaurants",
  },
  {
    id: "newsRestaurant",
    link: routes.newsRestaurant,
    icon: "files",
    title: "Actualité des restaurants",
  },
  {
    id: "announces",
    link: routes.announces,
    icon: "note",
    title: "Petites annonces",
  },
  {
    id: "health",
    link: routes.health,
    icon: "health",
    title: "Pages Santé Patek",
  },
  {
    id: "documentsImportant",
    link: routes.documentsImportant,
    icon: "files",
    title: "Documents Importants",
  },
  {
    id: "upgradeAdvice",
    link: routes.upgradeAdvice,
    icon: "announce",
    title: "Amélioration de l'App",
  },
];
export function AppContextInitial(): AppContextType {
  let defaultValues: AppContextType = {
    api: new PatekApi(),
    toast: new ToastHandler(),
    version: version,
    isOnline: navigator.onLine,
    token: undefined,
    homeItems: defaultHomeItems,
    updateHomeItems: () => console.warn("No Context"),
    getHomeItemTitle: () => "No Context",
    setIsOnline: () => console.warn("No Context"),
    readItems: {
      dontMiss: [],
      company: [],
      organisation: [],
      job: [],
      information: [],
      committee: [],
      sport: [],
      restaurant: [],
      event: [],
      delegation: [],
    },
    isRead: (key, value) => {
      console.warn("No Context");
      return false;
    },
    setReadItem: () => console.warn("No Context"),
    strings: undefined,
    setStrings: () => console.warn("No Context"),
    setUserInfo: () => console.warn("No Context"),
  };
  return defaultValues;
}
const InitialContext = AppContextInitial();

export const AppContext = React.createContext<AppContextType>(InitialContext);

export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const { accessToken: token } = useAccessToken();
  const [strings, setStrings] = useState<StaticData | undefined>(InitialContext.strings);
  const [readItems, setReadItems] = useState<ReadItems>(InitialContext.readItems);
  const [homeItems, setHomeItems] = useState<ListHomeItem[]>(InitialContext.homeItems);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const isRead = (key: ReadItemsKey, id: number) => {
    const found = readItems[key].find((e) => e.id === id);
    return found ? true : false;
  };
  const setReadItem = async (key: ReadItemsKey, id: number) => {
    // if (!readItems[key].find((e) => e.id === id)) {
    //   readItems[key].push({ id: id, publishDate: publishDate });
    // }
    if (!readItems[key].find((e) => e.id === id)) {
      readItems[key].push({ id: id, readAt: new Date().getTime() });
    }
    setReadItems(readItems);
    localforage.setItem(localForageKeys.readItems, readItems);
  };

  const updateHomeItems = (homeItemsData) => {
    const newItems: ListHomeItem[] = [];
    if (typeof homeItemsData === "object") {
      homeItemsData.forEach((item) => {
        const foundedItem = defaultHomeItems.find((homeItem) => homeItem.id === item.id);
        if (foundedItem) {
          newItems.push({ ...foundedItem, ...item });
        }
      });
      setHomeItems(newItems);
    }
  };

  const getHomeItemTitle = (id: HomeItemId) => {
    const item = homeItems.find((item) => item.id === id);
    return item ? item.title : "";
  };
  const contextStates: AppcontextState = {
    isOnline,
    setIsOnline,
    token,
    strings,
    setStrings,
    readItems,
    setReadItem,
    isRead,
    userInfo,
    setUserInfo,
    homeItems,
    updateHomeItems,
    getHomeItemTitle,
  };

  useEffect(() => {
    const handleConnection = () => {
      setIsOnline(navigator.onLine);
    };
    const getReadItemsFromStorage = async () => {
      const readItemsStorage = await localforage.getItem<ReadItems>(localForageKeys.readItems);
      if (readItemsStorage) {
        setReadItems({ ...InitialContext.readItems, ...readItemsStorage });
      }
    };
    getReadItemsFromStorage();
    window.addEventListener("online", handleConnection);
    window.addEventListener("offline", handleConnection);
  }, []);
  useEffect(() => {
    if (token) {
      InitialContext.api.setToken(token);
    }
  }, [token]);
  return (
    <AppContext.Provider value={{ ...InitialContext, ...contextStates }}>
      {children}
    </AppContext.Provider>
  );
};
