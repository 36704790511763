import React, { FunctionComponent, useContext } from "react"; // importing FunctionComponent
// import { NewsTypeLabel } from "src/helpers/enum";
import { List } from "src/components/organisms/lists/list/list";
import { Event } from "../../../molecules/event/event";
import moment from "src/helpers/moment";
import { EventItem } from "src/types/ApiItems";
import { AppContext } from "src/contexts/appContext";

type ListEventProps = {
  events: Array<EventItem>;
};
//{NewsTypeLabel[event.newsType]}
export const ListEvent: FunctionComponent<ListEventProps> = ({ events }) => {
  const { isRead } = useContext(AppContext);
  const allEvents = events.map((event, index) => {
    const isNew = event.isNew ? "new" : "";
    if (moment(event.endDate) >= moment()) {
      return (
        <Event
          startDate={event.startDate}
          endDate={event.endDate}
          title={event.title}
          notif={isNew}
          location={event.location}
          limit={event.endSignInDate}
          id={event.id}
          unread={!isRead("event", event.id) && event.isNew}
          key={index}
        />
      );
    }
    return null;
  });
  return <List>{allEvents}</List>;
};
